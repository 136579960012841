module.exports = {
  defaultTitle: 'Pratik',
  logo: 'https://www.pixlprime.com/img/core-img/logo.png',
  author: 'Pratik',
  // url: 'https://api.formium.io/submit/6140ca2d5e156b0001435296/feedback',
  url: 'https://api.formium.io/submit/6140ca2d5e156b0001435296/contact',
  key: '6LdYdGgcAAAAAHIDC7WhRAWPyOssXTLf0vVQL-kD',
  legalName: 'Prati Dev',
  defaultDescription: 'Take Photos and Stuff',
  socialLinks: {
    instagram: 'https://instagram.com/prakrtik',
  },
  googleAnalyticsID: 'G-0J2QG3KD5W',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    instagram: 'prakrtik',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'pixlprime',
    phone: 'phone number',
  },
  foundingDate: '2021',
};
